import { FC, PropsWithChildren } from "react";

const DialogButton: FC<PropsWithChildren<{ onClick?: () => void }>> = ({ children, onClick = () => {} }) => {
  return (
    <>
      <button onClick={() => onClick()}>{children}</button>
      <style jsx>
        {`
          button {
            color: white;
            background-color: #7e7e7e;
            border: 0;
            width: 100%;
            height: 29px;
          }
        `}
      </style>
    </>
  );
};

export default DialogButton;
